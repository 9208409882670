import pageInstance from "connection/pageInstance"
import { restoreParams } from "utils/historyPushWithStore"
import { Routes, Route } from "components/Router"
import List from "./containers/List"
import Employments from "./containers/Employments"
import Experiences from "./containers/Experiences"
import Edit from "./containers/Edit"
import Add from "./containers/Add"
import Show from "./containers/Show"
import Portfolios from "./containers/Portfolios"
import Documents from "./containers/Documents"

const Staffs = () => (
  <Routes>
    <Route
      path="/staffs"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/staffs`, {
          params: restoreParams(),
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/staffs/:staffId"
      fetchData={async ({ staffId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/staffs/${staffId}`, {
          signal
        })
        return data
      }}
      component={Show}
    />
    <Route
      path="/staffs/new"
      fetchData={async (params, _, { signal }) => {
        const { data } = await pageInstance.get(`/staffs/new`, {
          signal
        })
        return data
      }}
      component={Add}
    />
    <Route
      path="/staffs/:staffId/edit"
      fetchData={async ({ staffId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/staffs/${staffId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
    <Route
      path="/staffs/:staffId/employments"
      fetchData={async ({ staffId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/staffs/${staffId}/employments`, {
          signal
        })
        return data
      }}
      component={Employments}
    />
    <Route
      path="/staffs/:staffId/experiences"
      fetchData={async ({ staffId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/staffs/${staffId}/experiences`, {
          signal
        })
        return data
      }}
      component={Experiences}
    />
    <Route path="/staffs/:staffId/portfolios" component={Portfolios} />
    <Route path="/staffs/:staffId/documents" component={Documents} />
  </Routes>
)
export default Staffs

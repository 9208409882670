import pageInstance from "connection/pageInstance"
import { Routes, Route } from "components/Router"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"

const Documents = () => (
  <Routes>
    <Route
      path="/staffs/:staffId/documents"
      fetchData={async ({ staffId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/staffs/${staffId}/documents`, {
          signal
        })
        return data
      }}
      component={List}
    />
    <Route
      path="/staffs/:staffId/documents/new"
      fetchData={async ({ staffId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/staffs/${staffId}/documents/new`, {
          signal
        })
        return data
      }}
      component={Add}
    />
    <Route
      path="/staffs/:staffId/documents/:documentId/edit"
      fetchData={async ({ staffId, documentId }, _, { signal }) => {
        const { data } = await pageInstance.get(`/staffs/${staffId}/documents/${documentId}/edit`, {
          signal
        })
        return data
      }}
      component={Edit}
    />
  </Routes>
)
export default Documents

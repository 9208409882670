import React from "react"
import { Tab, Tabs } from "components/UI"

const TabsBlock = ({ staff: { id } }) => {
  const mapping = {
    [`/staffs/${id}`]: "Карточка персонала",
    [`/staffs/${id}/portfolios`]: "Портфолио",
    [`/staffs/${id}/employments`]: "Календарь занятости",
    [`/staffs/${id}/experiences`]: "Опыт",
    [`/staffs/${id}/documents`]: "Договор",
  }

  const paths = Object.keys(mapping)
  const onSelect = (i) => window.history.pushState(null, "", paths[i])
  const activeKey = paths.findIndex((i) => i === window.location.pathname)

  return (
    <Tabs activeKey={activeKey} onSelect={onSelect}>
      {Object.entries(mapping).map((i, index) => (
        <Tab key={i[0]} eventKey={index} title={i[1]} />
      ))}
    </Tabs>
  )
}

export default TabsBlock

import React, { Fragment } from "react"
import Breadcrumbs from "pages/Staffs/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card, Paginate } from "components/UI"
import useDocuments from "hooks/useDocuments"
import TabsBlock from "pages/Staffs/components/TabsBlock"
import Table from "./components/Table"

const List = ({ staff, ...props }) => {
  const { documents, fetchDocuments, destroyDocumentWithFetch } = useDocuments(props.documents, `/staffs/${staff.id}`)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem to={`/staffs/${staff.id}`}>{staff.fullName}</BreadcrumbItem>
        <BreadcrumbItem active>Документы</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock staff={staff} />
      <Card>
        <Button url={`/staffs/${staff.id}/documents/new`}>Создать</Button>
      </Card>
      <Table collection={documents} destroyDocumentWithFetch={destroyDocumentWithFetch} staff={staff} />
      <Paginate collection={documents} fetch={fetchDocuments} />
    </Fragment>
  )
}

export default List
